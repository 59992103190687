import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbContextMenuDirective } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';

import { Router } from '@angular/router';

import { Globals } from '../../../global';

import { map, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
	
  @ViewChild('list', { static: false }) templateList: TemplateRef<any>;
  
 // @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  userName: string;
  userPicture:string="";
  isAdmin: any = false;

  currentTheme = 'default';

  userMenu:any = [];
  
  locale:any = null;
  loadedLocale = false;
  
  totalNotifications:any = 0;
  feedbackNotifications:any = 10;
  rawTrialsNotifications:any = 10;

  constructor(private router: Router, private sidebarService: NbSidebarService, private menuService: NbMenuService, private themeService: NbThemeService, private layoutService: LayoutService, private breakpointService: NbMediaBreakpointsService, private globals: Globals, private translate: TranslateService) {
	  
	this.isAdmin = this.globals.isAdmin();
        this.locale = this.globals.getLocale();
	this.translate.setDefaultLang(this.locale);
        this.feedbackNotifications = sessionStorage.getItem('feedbacks');
        this.rawTrialsNotifications = sessionStorage.getItem('sourceClinicalTrials');
        
	this.totalNotifications = parseInt(this.feedbackNotifications) + parseInt(this.rawTrialsNotifications);
	
	  
  }

  ngOnInit() {
    this.userName = this.globals.getUserGlobal();

    const { xl } = this.breakpointService.getBreakpointsMap();
    
    this.menuService.onItemClick().subscribe(( event:any ) => {
      this.onItemSelection(event.item.name);
    })
    
    this.translate.use(this.locale);
    
     this.translate.get("profile").subscribe((resp: any)=>{
            this.userMenu = [{ name: 'profile', title: this.translate.instant("profile") },{ name: 'logout', title: this.translate.instant("close.session") }];
            this.loadedLocale = true;
       });
    
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.router.navigateByUrl("/pages/dashboard");
    return false;
  }
  
  setHasShow(){
      this.totalNotifications = 0;
  }
  
  onItemSelection(eventType) {
	switch(eventType){
	  case "profile":
	  
			  if(this.globals.isAdmin()){
				  
				  this.router.navigateByUrl("/pages/profile");
				//  this.contextMenu.hide();
				   
			  }else{
				  
				  this.router.navigateByUrl("/sponsor/profile");
				 // this.contextMenu.hide();
			  }
	  
			 
	  	break;
	  case "logout":
	    this.globals.forceLogOut();
	  	break;
	}
  }
}
