import { Injectable } from '@angular/core';
import { CompaniesData } from '../data/_companies';

@Injectable()
export class CompaniesService extends CompaniesData {

  data = [{
    idCompany: 1,
    name: 'Binima Consultores SL',
  }, {
    idCompany: 2,
    name: 'Coonic',
  }, {
    idCompany: 3,
    name: 'Devoteam',
  }, {
    idCompany: 4,
    name: 'Repsol',
  }, {
    idCompany: 5,
    name: 'Iberdrola',
  }, {
    idCompany: 6,
    name: 'Petronor',
  }, {
    idCompany: 7,
    name: 'Grupo Watio',
  }, {
    idCompany: 8,
    name: 'Lontana',
  }, {
    idCompany: 9,
    name: 'BCSC',
  }, {
    idCompany: 10,
    name: 'TapTaper',
  }, {
    idCompany: 11,
    name: 'Google',
  }];

  getData() {
    return this.data;
  }
}
