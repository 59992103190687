import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from './global';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyAuthService {

  url: any;
  token: any;
  userName: any;
  myHeaders: any = {
		'language': 'es',
		'sublanguage': 'es',
		'versioncode': '1.0.0',
		'timezone': 'es',
		'so': 'Web',
		'brand': 'Web',
		'model': 'Web',
		'country': 'es',
		'platform': 'Web',
		'Content-Type': 'application/x-www-form-urlencoded',
	    'Accept': 'application/json'
	};
  
  constructor(private http: HttpClient, private router: Router, private globals: Globals) { 
	this.url = globals.url;
	  
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
	  this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
	}else{
	  this.token = "";
	}
	    
    if (sessionStorage.getItem('userName') !== null) {
      this.userName = sessionStorage.getItem('userName');
    }else{
	  this.userName = null;  
    }
    
	this.myHeaders =  this.globals.defaultHeadersHttp(this.myHeaders);
	
  }
  
  
// API
  
  public loginService(emailUser: string, passwordUser: string, recaptcha: string) {
	var http_headers = new HttpHeaders(this.myHeaders); 
	
	var paramsUrl = new HttpParams().append('email', emailUser).append('password', passwordUser).append('recaptcha', recaptcha);
	
    return this.http.post(this.url + 'login', paramsUrl, {headers: http_headers});
  }
  
  
   public recoveryPasswordService(emailUser: string, recaptcha: string) {
	
	var http_headers = new HttpHeaders(this.myHeaders);
	
	var paramsUrl = new HttpParams().append('email', emailUser).append('recaptcha', recaptcha);

    return this.http.post(this.url + 'recoveryPassword', paramsUrl, {headers: http_headers});
    
  }
  
  public validateTokenService() {
	  
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}

	console.log("Validamos token");
	
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'validateToken', {headers: http_headers});
    
  }
  
  

//SW KI ABAJO S EPODRA BORRAR

// PROVINCES
  // Listado de provincias
  public showProvincesService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'provinces', {headers: http_headers});
  }
// END - PROVINCES

 
// HOME
  public showHomeInfoService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'home', {headers: http_headers});
  }
// END - HOME

 
// USERS
  // Listado de usuarios
  public showUsersService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'users', {headers: http_headers});
  }
  
  // Detalle de un usuario
  public showUserByIdService(userId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'user/' + userId, {headers: http_headers});
  }
  
  // Listado de fincas de un usuario
  public showUserFarmsService(userId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'user/' + userId + "/farms", {headers: http_headers});
  }
  
  // Listado de cultivos de un usuario
  public showUserCropsService(userId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'user/' + userId + "/crops", {headers: http_headers});
  }
  
  // Eliminar un usuario
  public deleteUserService(userId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'user/' + userId, {headers: http_headers});
  }
// END - USERS

// ADMIN USERS
  // Listado de usuarios administradores
  public showAdminUsersService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'adminUsers', {headers: http_headers});
  }
  
  // Detalle de un usuario administrador
  public showAdminByIdService(userId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'adminUser/' + userId, {headers: http_headers});
  }
  
  // Crear un usuario administrador
  public createAdminService(admin: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'adminUser', admin, {headers: http_headers});
  }
  
  // Editar un usuario administrador
  public editAdminService(adminUserId: any, admin: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'adminUser/' + adminUserId, admin, {headers: http_headers});
  }
  
  // Eliminar un usuario administrador
  public deleteAdminService(adminUserId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'adminUser/' + adminUserId, {headers: http_headers});
  }
// END - ADMIN USERS


// CROP TYPE
  // Listado de tipos de materias primas
  public showCropTypesService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'crop/types', {headers: http_headers});
  }
  
  // Detalle de un tipo de materia prima
  public showCropTypeByIdService(cropTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'crop/type/' + cropTypeId, {headers: http_headers});
  }
  
  // Crear un tipo de materia prima
  public createCropTypeService(cropType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'crop/type', cropType, {headers: http_headers});
  }
  
  // Editar un tipo de materia prima
  public editCropTypeService(cropTypeId: any, cropType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'crop/type/' + cropTypeId, cropType, {headers: http_headers});
  }
  
  // Eliminar un tipo de materia prima
  public deleteCropTypeService(cropTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'crop/type/' + cropTypeId, {headers: http_headers});
  }
// END - CROP TYPE


// CROP SUBTYPE
  // Listado de subtipos de materias primas
  public showCropSubTypesService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'crop/subTypes', {headers: http_headers});
  }
  
  // Detalle de un subtipo de materia prima
  public showCropSubTypeByIdService(cropSubTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'crop/subType/' + cropSubTypeId, {headers: http_headers});
  }
  
  // Crear un subtipo de materia prima
  public createCropSubTypeService(cropType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'crop/subType', cropType, {headers: http_headers});
  }
  
  // Editar un subtipo de materia prima
  public editCropSubTypeService(cropSubTypeId: any, cropSubType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'crop/subType/' + cropSubTypeId, cropSubType, {headers: http_headers});
  }
  
  // Eliminar un subtipo de materia prima
  public deleteCropSubTypeService(cropSubTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'crop/subType/' + cropSubTypeId, {headers: http_headers});
  }
// END - CROP SUBTYPE


// CROP SUBTYPE BY TYPE
  // Listado de subtipos de una materia prima
  public showCropSubTypesByCropTypeService(cropType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'crop/type/' + cropType + '/types', {headers: http_headers});
  }
// END - SUBTYPE BY TYPE


// MARKET
  // Listado de mercados
  public showMarketsService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'markets', {headers: http_headers});
  }
  
  // Detalle de una lonja/mercado
  public showMarketByIdService(marketId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'market/' + marketId, {headers: http_headers});
  }
  
  // Crear una lonja/mercado
  public createMarketService(market: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'market', market, {headers: http_headers});
  }
  
  // Editar una lonja/mercado
  public editMarketService(marketId: any, market: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'market/' + marketId, market, {headers: http_headers});
  }
  
  // Eliminar una lonja/mercado
  public deleteMarketService(marketId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'market/' + marketId, {headers: http_headers});
  }
// END - MARKET


// NEAR SITES
  // Listado de entornos
  public showNearSitesService(getParams: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'nearSites', {headers: http_headers, params: getParams});
  }
  
  // Detalle un entorno
  public showNearSiteByIdService(nearSiteId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'nearSite/' + nearSiteId, {headers: http_headers});
  }
  
  // Crear un entorno
  public createNearSiteService(nearSite: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'nearSite', nearSite, {headers: http_headers});
  }
  
  // Editar un entorno
  public editNearSiteService(nearSiteId: any, nearSite: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'nearSite/' + nearSiteId, nearSite, {headers: http_headers});
  }
  
  // Eliminar un entorno
  public deleteNearSiteService(nearSiteId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'nearSite/' + nearSiteId, {headers: http_headers});
  }
// END - NEAR SITES


// NEAR SITE TYPES
  // Listado de entornos
  public showNearSiteTypesService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'nearSiteTypes', {headers: http_headers});
  }
  
  // Detalle un tipo de entorno
  public showNearSiteTypeByIdService(nearSiteTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'nearSiteType/' + nearSiteTypeId, {headers: http_headers});
  }
  
  // Crear un tipo de entorno
  public createNearSiteTypeService(nearSiteType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'nearSiteType', nearSiteType, {headers: http_headers});
  }
  
  // Editar un tipo de entorno
  public editNearSiteTypeService(nearSiteTypeId: any, nearSiteType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'nearSiteType/' + nearSiteTypeId, nearSiteType, {headers: http_headers});
  }
  
  // Eliminar un tipo de entorno
  public deleteNearSiteTypeService(nearSiteTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'nearSiteType/' + nearSiteTypeId, {headers: http_headers});
  }
// END - NEAR SITE TYPES


// NEAR SITES PHOTOS
  // Subir una photo a un entorno
  public createNearSitePhotoService(nearSiteId: any, nearSitePhoto: any) {
	const formData = new FormData();  
    formData.append('image', nearSitePhoto);
    
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.delete('Content-Type');
	
    return this.http.post(this.url + 'nearSite/' + nearSiteId + '/photo', formData, {headers: http_headers});
  }
  
  // Eliminar una photo de un entorno
  public deleteNearSitePhotoService(nearSiteId: any, nearSitePhotoId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'nearSite/' + nearSiteId + '/photo/' + nearSitePhotoId, {headers: http_headers});
  }
// END - NEAR SITES


// MARKET PRICES
  // Listado de precios de materias primas
  public showMarketCropTypesService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'marketPrices', {headers: http_headers});
  }
  
  // Listado de precios de materias primas en un mercado
  public showMarketCropTypesByMarketService(marketId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'market/' + marketId + '/prices', {headers: http_headers});
  }
  
  // Detalle del precio de una materia prima en un mercado
  public showMarketCropTypeByIdService(marketId: any, marketCropTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'market/' + marketId + '/cropType/' + marketCropTypeId, {headers: http_headers});
  }
  
  // Crear precio para una materia prima en un lonja/mercado
  public createMarketPriceService(marketId: any, marketCropType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'market/' + marketId + '/cropType', marketCropType, {headers: http_headers});
  }
  
  // Editar el precio para una materia prima en un lonja/mercado
  public editMarketPriceService(marketId: any, marketCropTypeId: any, marketCropType: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'market/' + marketId + '/cropType/' + marketCropTypeId, marketCropType, {headers: http_headers});
  }
  
  // Eliminar el precio de una materia prima en un lonja/mercado
  public deleteMarketPriceService(marketId: any, marketCropTypeId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'market/' + marketId + '/cropType/' + marketCropTypeId, {headers: http_headers});
  }
// MARKET PRICES


// AD CATEGORIES
  // Listado de categorías de los anuncios
  public showAdCategoriesService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'adCategories', {headers: http_headers});
  }
  
  // Detalle una categoría de anuncios
  public showAdCategoryByIdService(adCategoryId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'adCategory/' + adCategoryId, {headers: http_headers});
  }
  
  // Crear una categoría de anuncios
  public createAdCategoryService(adCategory: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'adCategory', adCategory, {headers: http_headers});
  }
  
  // Editar una categoría de anuncios
  public editAdCategoryService(adCategoryId: any, adCategory: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'adCategory/' + adCategoryId, adCategory, {headers: http_headers});
  }
  
  // Eliminar una categoría de anuncios
  public deleteAdCategoryService(adCategoryId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'adCategory/' + adCategoryId, {headers: http_headers});
  }
// END - AD CATEGORIES
  
  
// ADS
  // Listado de anuncios
  public showAdsService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'ads', {headers: http_headers});
  }
  
  // Detalle de un anuncio
  public showAdByIdService(adId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'ad/' + adId, {headers: http_headers});
  }
  
  // Eliminar un anuncio
  public deleteAdService(adId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.delete(this.url + 'ad/' + adId, {headers: http_headers});
  }
  
  // Bloquear un anuncio
  public blockAdService(adId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.post(this.url + 'ad/' + adId + '/block', null, {headers: http_headers});
  }
// END - USERS


// PUBLIC PAGES
  // Listado de páginas públicas
  public showPublicPagesService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'settings/publicPages', {headers: http_headers});
  }
  
  // Detalle una página pública
  public showPublicPageByIdService(publicPageId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'settings/publicPage/' + publicPageId, {headers: http_headers});
  }
  
  // Editar una página pública
  public editPublicPageService(publicPageId: any, publicPage: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'settings/publicPage/' + publicPageId, publicPage, {headers: http_headers});
  }
// END - PUBLIC PAGES


// FAQ's
  // Listado una FAQ
  public showFaqsService() {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'settings/faqs', {headers: http_headers});
  }
  
  // Detalle una FAQ
  public showFaqByIdService(faqId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	
    return this.http.get(this.url + 'settings/faq/' + faqId, {headers: http_headers});
  }
  
  // Crear una FAQ
  public createFaqService(faq: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.post(this.url + 'settings/faq', faq, {headers: http_headers});
  }
  
  // Editar una FAQ
  public editFaqService(faqId: any, faq: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.put(this.url + 'settings/faq/' + faqId, faq, {headers: http_headers});
  }
  
  // Elininar una FAQ
  public deleteFaqService(faqId: any) {
	if (localStorage.getItem('auth_token') !== null || sessionStorage.getItem('auth_token') !== null) {
		this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || "0";
		this.myHeaders.token = this.token;
	}
	var http_headers = new HttpHeaders(this.myHeaders);
	http_headers = http_headers.set('Content-Type', 'application/json; charset=utf-8');
	
    return this.http.delete(this.url + 'settings/faq/' + faqId, {headers: http_headers});
  }
// END - FAQ's
  

}
