import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { get } from 'http';

@Injectable()
export class Globals {

  ADMIN_ROLE_ID: string = '3';
  url: string = environment.apiUrl;
  token: string | null = '';

  constructor(private http: HttpClient, private router: Router) {
    this.token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token') || '';
  }

  getTokenGlobal(){
    return this.token;
  }

  getUserGlobal(){
    return localStorage.getItem('userName') || sessionStorage.getItem('userName') || '';
  }

  getUserIdGlobal(){
    return localStorage.getItem('userId') || sessionStorage.getItem('userId') || null;
  }

  forceLogOut() {
    const rememberMe = localStorage.getItem('rememberMe');
    const loginEmail = localStorage.getItem('loginEmail');
    const loginPassword = localStorage.getItem('loginPassword');

    localStorage.clear();
    sessionStorage.clear();

    localStorage.setItem('rememberMe', rememberMe);
    if (
      rememberMe
      && rememberMe === 'true'
      && loginEmail != null
    ) {
      localStorage.setItem('loginEmail', loginEmail);
      localStorage.setItem('loginPassword', loginPassword);
    }
    
    this.router.navigate(['auth/login']);
    location.reload();
  }

  validURL(myURL): boolean {
    const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return urlRegex.test(myURL);
  }  

  isAdmin(){
    return sessionStorage.getItem('rolId') == this.ADMIN_ROLE_ID;
  }


	 getLocale (): 'es-ES' | 'en-GB' | 'fr-FR' {
    const userLang = navigator.language;

    if ( /^fr/i.test(userLang) ){ 
      return 'fr-FR';
    } 
    
    return /^en/i.test(userLang)
      ? 'en-GB'
      : 'es-ES';
	  }

	  getLocaleFormat(): string{
      return this.getLocale() === 'es-ES'
        ? 'dd-MM-yyyy'
        : 'yyyy-MM-dd';
	  }

    getUserIdRole(): string | null {
		  return sessionStorage.getItem('rolId');
	  }

    getUserIdSponsor(): string | null {
		  return sessionStorage.getItem('idSponsor');
	  }


	defaultHeadersHttp(headers){
		const locale = this.getLocale();

		let localeLanguage = 'es';
    switch (locale) {
      case 'fr-FR':
        localeLanguage = 'fr';
        break;
      case 'en-GB':
        localeLanguage = 'en';
        break;
      default:
        localeLanguage = 'es';
    }

		headers.language = localeLanguage;
		headers.sublanguage = localeLanguage;

		return headers;
	}

  validEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  getEnvironment() {
    let environment = '';
    if (this.url.includes('demoapi')) {
      environment = 'environment.preproduction';
    } else if (this.url.includes('127.0.0.1') || this.url.includes('localhost')) {
      environment = 'environment.development';
    }

    return environment;
  }

  getStorageData (key) {
    const local = localStorage.getItem(key);
    const session = sessionStorage.getItem(key);

    return local && local !== 'null'
      ? local
      : session && session !== 'null'
        ? session
        : null;
  }

	getRememberMe(){
    const rememberMe = this.getStorageData('rememberMe');

    return rememberMe && rememberMe !== null;
	}

	getLoginSaveEmail(){
    return localStorage.getItem('loginEmail');
	}

	getLoginSavePassword(){
    return localStorage.getItem('loginPassword');
	}

	encodePasswordToStorage(password){
		return window.btoa(unescape(encodeURIComponent( password )));
	}

	decodePasswordToStorage(password){
    return password !== null
      ? decodeURIComponent(escape(window.atob( password )))
      : null;
	}
}
