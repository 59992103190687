import { Component } from '@angular/core';

import { Globals } from '../../../global';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `<div class="environment" *ngIf="environment != ''">{{ environment | translate }}</div>`,
})
export class FooterComponent {
	environment: string = "";
	
	constructor(private globals: Globals) {
		this.environment = this.globals.getEnvironment();
	}
}
