<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()"><img class="menuImg" src="assets/images/logo.svg"/></a>
    </div>
</div>

<div class="header-container">

    <button *ngIf="isAdmin" ghost [nbPopover]="list" nbPopoverPlacement="bottom" nbPopoverTrigger="click" nbButton class="notifications" (click)="setHasShow()">
        <nb-icon icon="bell-outline"></nb-icon>
        <nb-badge *ngIf="totalNotifications > 0" text="" status="warning" class="notificationsMaster notifications"></nb-badge>
    </button>

    <ng-template #list> 
        <nb-card class="mb-0">
           <nb-list>
                <nb-list-item>
                    <div class="d-flex justify-content-between">{{'raw.trials'| translate}} <nb-badge text="{{rawTrialsNotifications}}" status="warning" class="notifications  float-right ml-5"></nb-badge></div>
                </nb-list-item>
                <nb-list-item>
                    <div class="d-flex justify-content-between">{{'feedback'| translate}} <nb-badge text="{{feedbackNotifications}}" status="warning" class="notifications  float-right ml-5"></nb-badge></div>
                </nb-list-item>
            </nb-list>
        </nb-card>
    </ng-template>


    <nb-actions size="small">


        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>


        <!--
            <nb-action class="control-item">
              <nb-search type="rotate-layout"></nb-search>
            </nb-action>
        -->
        <!--     <nb-action class="control-item" icon="email-outline"></nb-action> -->
        
        <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
            <nb-user *ngIf="loadedLocale" [nbContextMenu]="userMenu"
                     [nbContextMenuTag]="header-menu-user"
                     (itemClick)="onMenuItemClick($event)"
                     [name]="userName"
                     [picture]="userPicture"
                     >
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
