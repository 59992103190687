import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MyAuthService } from './auth.service';
import { Globals } from './global';

@Injectable()
export class AuthGuard implements CanActivate {
	token;
	userName;

	constructor(private myAuthService: MyAuthService, private router: Router, private globals: Globals) { }
	
	canActivate() {
		this.token = this.globals.getTokenGlobal();
		
		if(this.token !== null){
			return this.myAuthService.validateTokenService().pipe(map((resp: any) => {
				if(resp.error != null){
					this.globals.forceLogOut();
					return false;
				}else{
					//console.log(resp.user.notifications);
                                        
					if(resp.hasOwnProperty('notifications') && resp.notifications !== null  ){
						
						if(resp.notifications.hasOwnProperty('feedbacksNotResponse') && resp.notifications.feedbacksNotResponse !== null  ){
							sessionStorage.setItem('feedbacks', resp.notifications.feedbacksNotResponse);
						}else{
							sessionStorage.setItem('feedbacks', '0');
						}
						
						if(resp.notifications.hasOwnProperty('crinicalTrialModified') && resp.notifications.crinicalTrialModified !== null  ){
							sessionStorage.setItem('sourceClinicalTrials', resp.notifications.crinicalTrialModified);
						}else{
							sessionStorage.setItem('sourceClinicalTrials', '0');
						}
						
					}else{
						sessionStorage.setItem('feedbacks', '0');
						sessionStorage.setItem('sourceClinicalTrials', '0');
					}
                              
                                        
					if(this.globals.isAdmin()){
						return true;
					}else{
						this.globals.forceLogOut();
						return false;
					}
				}
			}));
		} else {
			return false;
		}
	}
}