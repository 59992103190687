import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MyAuthService } from './auth.service';
import { Globals } from './global';

@Injectable()
export class LoginGuard implements CanActivate {
	token;
	userName;

	constructor(private myAuthService: MyAuthService, private router: Router, private globals: Globals) { }
	
	canActivate() {
		this.token = this.globals.getTokenGlobal();
		if(this.token !== null){
			return this.myAuthService.validateTokenService().pipe(map((resp: any) => {
				if(resp.error == null){
					this.router.navigate(['pages']);
				}
				return true;
			}));
		} else {
			return true;
		}
		return true;
	}
}