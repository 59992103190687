import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';

 import { FormsModule } from '@angular/forms';
import { EnsayosTabComponent } from '../components/tabs/ensayos/ensayos.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { NbThemeModule } from '@nebular/theme';
import {NbAlertModule, NbCardModule, NbIconModule, NbInputModule, NbSelectModule, NbButtonModule, NbTabsetModule, NbTreeGridModule, NbCheckboxModule, NbListModule, NbRadioModule} from '@nebular/theme';


 @NgModule({
   declarations: [
     EnsayosTabComponent
   ],
   imports: [
   FormsModule,
     CommonModule,
     TranslateModule,
     Ng2SmartTableModule,
     NbThemeModule,
     NbAlertModule, NbCardModule, NbIconModule, NbInputModule, NbSelectModule, NbButtonModule, NbTabsetModule, NbTreeGridModule, NbCheckboxModule, NbListModule, NbRadioModule
   ],
   exports: [
     EnsayosTabComponent
   ]
 })
 export class SharedModule { }