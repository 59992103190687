import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { AuthGuard } from './auth.guard';
import { LoginGuard } from './login.guard';
import { SponsorGuard } from './sponsor.guard';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) ,
  },
  {
    path: 'sponsor',
    canActivate: [SponsorGuard],
    loadChildren: () => import('./sponsor/sponsor.module').then(m => m.SponsorModule) ,
  },
  {
    path: "auth",
    canActivate: [LoginGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.NgxAuthModule)
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
